@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";

/* Override gallery style */
.image-gallery-slides {
    height: 60vh !important;
}

.image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
    max-height: 60vh;
}

/* Customizing the scrollbar */
::-webkit-scrollbar {
    width: 3px;
    /* Width of the scrollbar */
}

/* Track (background) of the scrollbar */
::-webkit-scrollbar-track {
    background-color: #f9f9f900;
}

/* Handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
    background-color: rgba(204, 202, 202, 0.6);
    border-radius: 10px;
}

/* On hover, make the handle darker */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* On scrollbar drag, make the handle even darker */
::-webkit-scrollbar-thumb:active {
    background-color: #333;
}

/* Hide the horizontal scrollbar while allowing horizontal overflow */
body {
    overflow-x: hidden;
}